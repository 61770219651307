import type { SuperHubAPIDefinitionSlice } from './ApiDefinitions';
import type { SuperHubDocumentSlice } from './Document';
import type { SuperHubEditorSlice } from './Editor';
import type { SuperHubSidebarSlice } from './Sidebar';
import type { SuperHubVersionSlice } from './Versions';
import type { Flag, ProjectClientSide } from '@readme/backend/models/project/types';

import { matchPath } from 'react-router-dom';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { projectStore } from '@core/store/Project';

import { SuperHubHashRoutePaths, SuperHubRoutePaths } from '@routes/SuperHub/types';
import type { SuperHubHashRouteParams, SuperHubRouteParams } from '@routes/SuperHub/types';

import { actionLog, createBoundedUseStore, isClient } from '../util';

import { createSuperHubAPIDefinitionSlice } from './ApiDefinitions';
import { createSuperHubDocumentSlice } from './Document';
import { createSuperHubEditorSlice } from './Editor';
import { createSuperHubSidebarSlice } from './Sidebar';
import { createSuperHubVersionSlice } from './Versions';

interface SuperHubStoreState {
  /**
   * Base URL pointing to our v2 API endpoint. Consumers can simply start with
   * this and tack on additional segments to reach different API endpoints. Use
   * `apiBaseUrlSansVersion` for endpoints without a version.
   * @example /subdomain/api-next/v2/versions/1.0
   * @example
   * ```ts
   * fetch(`${apiBaseUrl}/sidebar`)
   * ```
   */
  apiBaseUrl: string;

  /**
   * Base URL to our v2 API endpoint without the version segment. Use this when
   * accessing resources that aren't versioned, e.g. `/versions`, `/changelogs`.
   * @example /subdomain/api-next/v2
   * @example
   * ```ts
   * fetch(`${apiBaseUrlSansVersion}/versions`)
   * ```
   */
  apiBaseUrlWithoutVersion: string;

  /**
   * Default SuperHub options object for the useReadmeApi hook
   */
  defaultApiOptions: {
    useLegacyBaseUrl: boolean;
  };

  /**
   * Whether the current project is an Enterprise project or not.
   */
  enterprise?: {
    /**
     * Whether the current project is a child of an Enterprise group.
     */
    isChild: boolean;
    /**
     * Whether the current project is an Enterprise group.
     */
    isGroup: boolean;
    /**
     * Whether the Single Project Enterprise flag is enabled.
     * (The child inherits this flag from the parent group.)
     */
    isSingleProject: boolean;
  };

  /**
   * Indicates whether SuperHub should allow access to bidirectional sync
   */
  isBidiSync: boolean;

  /**
   * True when pages goes into "edit" mode.
   */
  isEditing: boolean;

  /**
   * Whether or not My Developers panel is expanded (defaults to true).
   */
  isMyDevPanelExpanded: boolean;

  /**
   * Indicates that the store has been initialized to its beginning state via
   * `initialize()` action and lets connected subscribers know that it is ready
   * for consumption.
   * @see initialize
   */
  isReady: boolean;

  /**
   * Indicates whether the current route is on the API definitions list view
   * within the Reference section. Mainly used as a convenience that inspects
   * route segments to determine which reference section view to load.
   */
  isReferenceDefinitionsRoute: boolean;

  /**
   * Indicates whether SuperHub is enabled for the current project. Will be
   * `true` for all user roles including both admins and logged-out users.
   */
  isSuperHub: boolean;

  /**
   * Indicates whether the current user is a SuperHub admin that can manage
   * project settings and edit content.
   */
  isSuperHubAdmin: boolean;

  /**
   * Indicates whether the feature flag for the manage versions UI is enabled.
   */
  isSuperHubManageVersionsEnabled: boolean;

  /**
   * Determines which layout to render the SuperHub in.
   * - `default`: Default layout for the SuperHub.
   * - `aside`: Used when comparing two documents side-by-side.
   * - `aside-standalone`: Same as `aside` but without a top nav bar.
   * - `edit`: Used when editing a document.
   * - `my-developers`: Used when viewing My Developers panels.
   * - `settings`: Used when configuring settings. The Hub content is hidden in this layout.
   * - `settings-preview`: Used when configuring settings side-by-side with the Hub content.
   */
  layout:
    | 'aside-standalone'
    | 'aside'
    | 'default'
    | 'edit'
    | 'my-developers'
    | 'settings-preview'
    | 'settings'
    | 'versions';

  /**
   * Refers to the top-level route action that is being performed on any given
   * section. For example, when navigating to `/update/reference/pet`, the
   * action is `update`, which indicates we are editing a Reference page with the
   * slug named `pet`.
   */
  routeAction: NonNullable<SuperHubRouteParams['action']> | null;

  /**
   * Refers to the top-level route section that is being viewed on the Hub such
   * as `docs`, `reference`, `changelog`, etc. For example. when navigating to
   * `/reference/pet`, the top-level route section is `reference`. This is
   * typically but not always the first segment of the route URL.
   */
  routeSection: NonNullable<SuperHubRouteParams['section']> | null;

  /**
   * Contains the page slug from the hub route that is currently being viewed.
   * For example, when viewing `/docs/getting-started`, the slug is
   * `getting-started`, typically the second segment of the hub route.
   */
  slug: string | null;

  /**
   * Project subdomain that is currently in view, e.g. `my-project-slug`
   */
  subdomain: string | null;

  /** Info about the currently logged in user. */
  user: {
    /** Logged in user's ID, e.g. `user.teammateUserId`. */
    id: string | null;
    /** Logged in user's name, e.g. `Banjo Stevens`. */
    name: string | null;
  };
}

interface SuperHubStoreAction {
  /**
   * Generates the URL path to reach the API endpoint related to the current
   * document type. Used by actions to create, edit or view documents.
   * @example
   * /subdomain/api-next/v2/versions/1.0/guides
   * /subdomain/api-next/v2/versions/1.0/custom_pages/slug
   */
  getApiEndpoint: (slug?: string) => string | null;

  /**
   * Initializes the store based on the provided settings. In order to enable
   * SSR, this needs to be called inline and high up in the rendering tree to
   * make the store's state ready before rendering components downstream during
   * both SSR and the initial CSR.
   */
  initialize: (
    settings: Pick<SuperHubVersionSlice['versions'], 'currentVersion' | 'defaultVersion'> & {
      /**
       * Whether currently logged in user has admin permissions or not.
       */
      isAdminUser: boolean;

      /**
       * Whether the current project is a child of an Enterprise group
       */
      isEnterpriseChild?: boolean;

      /**
       * Whether the current project is an Enterprise group
       */
      isEnterpriseGroup?: boolean;
      /**
       * Browser location pathname and hash to initialize the store to.
       * @example
       * ['/docs/getting-started', '#/configure/appearance/theme-editor']
       */
      location?: [pathname: string, hash?: string];
      /**
       * Project feature flags that are relevant to SuperHub in order to determine
       * whether it should be enabled or not.
       */
      projectFlags: Partial<
        Pick<ProjectClientSide['flags'], Flag.BIDI_SYNC | Flag.SUPERHUB | Flag.SUPERHUB_MANAGE_VERSIONS>
      >;
      /**
       * Project subdomain.
       * @example my-project
       */
      subdomain: SuperHubStoreState['subdomain'];
      /** Info about the currently logged in user. */
      user?: {
        id: SuperHubStoreState['user']['id'];
        name: SuperHubStoreState['user']['name'];
      };
    },
  ) => void;

  /**
   * Resets the entire store back to its initial default state.
   */
  reset: () => void;

  /**
   * Toggles the `isMyDevPanelExpand` state.
   */
  updateIsMyDevPanelExpanded: () => void;

  /**
   * Updates the current route we've currently navigated to with the provided
   * URL pathname and hash properties. Segments in each property are then parsed
   * and interpreted by our state.
   * @see ConnectSuperHubStoreToRouter
   */
  updateRoute: (pathname: string, hash?: string) => void;
}

export type SuperHubStore = SuperHubAPIDefinitionSlice &
  SuperHubDocumentSlice &
  SuperHubEditorSlice &
  SuperHubSidebarSlice &
  SuperHubStoreAction &
  SuperHubStoreState &
  SuperHubVersionSlice;

const initialState: SuperHubStoreState = {
  apiBaseUrl: '',
  apiBaseUrlWithoutVersion: '',
  defaultApiOptions: {
    useLegacyBaseUrl: false,
  },
  isBidiSync: false,
  isEditing: false,
  isMyDevPanelExpanded: true,
  isReady: false,
  isReferenceDefinitionsRoute: false,
  isSuperHub: false,
  isSuperHubAdmin: false,
  isSuperHubManageVersionsEnabled: false,
  layout: 'default',
  routeAction: null,
  routeSection: null,
  slug: null,
  subdomain: null,
  user: { id: null, name: null },
};

/**
 * Vanilla store that contains all application-level state data required by
 * SuperHub. This store can be accessed and used anywhere. React components
 * should call `useSuperHubStore()` instead.
 * @example
 * import { superHubStore } from '@core/store';
 *
 * const isEditing = superHubStore.getState().isEditing;
 */
export const superHubStore = createStore<SuperHubStore>()(
  devtools(
    immer((set, get, ...props) => {
      /**
       * Holds reference to the initial state so we can support resetting the
       * store back to this state when calling `reset()`.
       */
      const resetState = {
        ...createSuperHubAPIDefinitionSlice(set, get, ...props),
        ...createSuperHubDocumentSlice(set, get, ...props),
        ...createSuperHubEditorSlice(set, get, ...props),
        ...createSuperHubSidebarSlice(set, get, ...props),
        ...createSuperHubVersionSlice(set, get, ...props),
        ...initialState,
      };

      return {
        ...resetState,

        getApiEndpoint: slug => {
          const { apiBaseUrl, apiBaseUrlWithoutVersion: apiBaseUrlSansVersion, routeSection } = get();
          if (!routeSection) return null;

          const apiType = {
            changelog: 'changelogs',
            docs: 'guides',
            reference: 'reference',
            page: 'custom_pages',
            recipes: 'recipes',
          }[routeSection];

          // Some sections are not versioned. So we strip away that segment
          // from the API url for those cases.
          const nonVersionedTypes = ['changelogs'];
          const baseUrl = nonVersionedTypes.includes(apiType) ? apiBaseUrlSansVersion : apiBaseUrl;

          return [baseUrl, apiType, slug].filter(Boolean).join('/');
        },

        initialize: settings => {
          // When store is initializing during SSR, we have to explicitly reset
          // it to its initial state. Otherwise, there could be remnants of
          // stale data leftover in the store from a previous SSR. This is b/c
          // our store is a singleton and never gets destroyed on the server.
          if (!isClient) get().reset();

          const {
            currentVersion,
            defaultVersion,
            isAdminUser,
            isEnterpriseChild,
            isEnterpriseGroup,
            location = [],
            projectFlags: flags,
            subdomain,
          } = settings;
          set(
            state => {
              state.apiBaseUrl = `/${subdomain}/api-next/v2/versions/${currentVersion}`;
              state.apiBaseUrlWithoutVersion = `/${subdomain}/api-next/v2`;
              state.isReady = isClient;
              state.isSuperHub = !!flags.superHub;
              state.isSuperHubManageVersionsEnabled = !!flags.superHubManageVersions;
              state.isSuperHubAdmin = state.isSuperHub && isAdminUser;
              state.isBidiSync = state.isSuperHubAdmin && !!flags.bidiSync;
              state.subdomain = subdomain;
              state.user = {
                id: settings.user?.id || null,
                name: settings.user?.name || null,
              };
              state.versions.currentVersion = currentVersion;
              state.versions.defaultVersion = defaultVersion;

              if (isEnterpriseGroup || isEnterpriseChild) {
                state.enterprise = {
                  isGroup: !!isEnterpriseGroup,
                  isChild: !!isEnterpriseChild,
                  isSingleProject: 'singleProjectEnterprise' in flags && !!flags.singleProjectEnterprise,
                };
              }
            },
            false,
            actionLog('initialize', settings),
          );

          // Continue initialization based on the location route.
          const [pathname, hash] = location;
          if (pathname) {
            get().updateRoute(pathname, hash);
          }
        },

        reset: () => {
          set(resetState, false, actionLog('reset'));
          get().sidebar.updateCache();
        },

        updateIsMyDevPanelExpanded: () => {
          set(
            state => {
              state.isMyDevPanelExpanded = !state.isMyDevPanelExpanded;
            },
            false,
            actionLog('updateIsMyDevPanelExpanded'),
          );
        },

        updateRoute: (pathname, hash = '') => {
          // Match the same route paths from our superhub routers to parse out
          // route params in the same way.
          const match = matchPath<SuperHubRouteParams>(pathname, Object.values(SuperHubRoutePaths));
          const hashMatch = matchPath<SuperHubHashRouteParams>(
            hash.substring(1),
            Object.values(SuperHubHashRoutePaths),
          );

          const {
            params: { action = null, section = pathname === '/' ? 'home' : null, slug = null },
          } = match ?? { params: {} };
          const {
            params: { action: hashAction, section: hashSection = '' },
          } = hashMatch ?? { params: {} };

          set(
            state => {
              state.isEditing = ['create', 'update', 'compare'].includes(action || '');
              state.isReferenceDefinitionsRoute = action === 'update' && section === 'reference' && !slug;
              state.routeAction = action;
              state.routeSection = section;
              state.slug = slug;
              state.editor.isCreateNewPage = action === 'create';

              // ⚠️ Be mindful of order when updating!! Hash actions should be
              // evaluated before actions, to ensure that hash action based
              // panel overlays take precedense over the main action
              if (action === 'compare') {
                state.layout = 'aside-standalone';
              } else if (section === 'reference' && hashAction === 'create' && hashSection === 'api-definition') {
                state.layout = 'aside';
              } else if (hashAction === 'versions') {
                state.layout = 'versions';
              } else if (hashAction === 'my-developers') {
                state.layout = 'my-developers';
              } else if (hashAction === 'configure' || hashAction === 'content') {
                state.layout = 'settings';
              } else if (hashAction === 'appearance') {
                state.layout = 'settings-preview';
              } else if (action === 'create') {
                state.layout = 'edit';
              } else if (action === 'update') {
                state.layout = 'edit';
              } else {
                state.layout = 'default';
              }

              if (['configure', 'content', 'appearance', 'my-developers'].includes(hashAction ?? '')) {
                // Always clear project store errors when re-routing.
                projectStore.getState().resetSaveError();
              }
            },
            false,
            actionLog('updateRoute', { pathname, hash }),
          );
        },
      };
    }),
    { name: 'SuperHubStore' },
  ),
);

/**
 * Bound react hook to access our SuperHub store. Must be called within a React
 * component. To access the store outside of React, use `superHubStore` instead.
 * @example
 * import { useSuperHubStore } from '@core/store';
 *
 * function Component() {
 *   const isEditing = useSuperHubStore(s => s.isEditing);
 * }
 */
export const useSuperHubStore = createBoundedUseStore(superHubStore);

export * from './ApiDefinitions';
export * from './ConnectSuperHubStoreToRouter';
export * from './Document';
export * from './Editor';
export * from './InitializeSuperHubStore';
export * from './Sidebar';
export * from './Versions';
