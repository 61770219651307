import type { CreateVersionType, ReadVersionCollectionType, ReadVersionType } from './mock';
import type { SuperHubStore } from '..';
import type { SetOptional, WritableDeep } from 'type-fest';
import type { StateCreator } from 'zustand';

import produce from 'immer';
import { mutate } from 'swr';

import type useReadmeApi from '@core/hooks/useReadmeApi';
import { fetcher } from '@core/hooks/useReadmeApi';
import { actionLog, isClient } from '@core/store/util';
import type { APIErrorType } from '@core/types';

interface SuperHubVersionSliceState {
  /**
   * Current project version that is in view, e.g. `1.0`.
   */
  currentVersion: string;

  /**
   * Paginated collection data containing a project's git versions.
   */
  data: ReadVersionCollectionType;

  /**
   * Default project version (also known as the "stable" project version). This
   * is the version end users are taken to by default, e.g. `2.0`.
   */
  defaultVersion: string;

  /**
   * Contains error information that occurred during hydration or updates.
   * @todo Make this a more specific type that matches the error we expect to
   * receive from the `/versions` endpoint.
   * @link https://linear.app/readme-io/issue/RM-11921/handle-errors-coming-back-from-our-api-endpoints
   */
  error: APIErrorType | null;

  /**
   * Whether versions data is currently being fetched via API.
   */
  isLoading: boolean;

  /**
   * Indicates this slice has been initialized on both server and client. We use
   * this to differentiate how the state should update during SSR vs CSR. On the
   * server, the state should never prevent updates or retain previously
   * existing data. This is only safe to do once this flag is flipped to `true`.
   * @see initialize
   */
  isReady: boolean;

  /**
   * Holds reference to the SWR request key that is used to fetch versions data
   * from our API endpoint. This key is used by SWR's `mutate()` function to
   * update the SWR cache after a mutation request returns successful.
   * @link https://swr.vercel.app/docs/mutation
   * @example
   * ```ts
   * ['/cats/api-next/v2/versions', {}]
   * ```
   */
  swrKey: ReturnType<typeof useReadmeApi>['swrKey'];
}

interface SuperHubVersionSliceAction {
  /**
   * Creates a new version that is forked from another version.
   */
  createVersion: (version: CreateVersionType) => Promise<ReadVersionType>;

  /**
   * Deletes a version.
   */
  deleteVersion: (name: ReadVersionType['data']['name']) => Promise<void>;

  /**
   * Updates this state slice with initial data, loading state, error, etc.
   */
  initialize: (
    payload: SetOptional<Pick<SuperHubVersionSliceState, 'data' | 'error' | 'isLoading' | 'swrKey'>, 'data' | 'swrKey'>,
  ) => void;

  /**
   * Renames an existing version to a different name.
   */
  renameVersion: (name: ReadVersionType['data']['name'], newName: string) => Promise<ReadVersionType>;

  /**
   * Revalidates versions data by refetching data from our API endpoint.
   */
  revalidate: () => Promise<ReadVersionCollectionType | undefined>;

  /**
   * Updates an existing version.
   */
  updateVersion: (
    name: ReadVersionType['data']['name'],
    updatedVersion: Partial<ReadVersionType['data']>,
  ) => Promise<ReadVersionType>;
}

export interface SuperHubVersionSlice {
  /**
   * State slice containing git versions data for the current project and all
   * actions that can be performed against it.
   */
  versions: SuperHubVersionSliceAction & SuperHubVersionSliceState;
}

const initialState: SuperHubVersionSliceState = {
  currentVersion: '',
  defaultVersion: '',
  data: {
    data: [],
    per_page: 0,
    total: 0,
  },
  error: null,
  isLoading: false,
  isReady: false,
  swrKey: null,
};

/**
 * Versions state slice containing all things related to project versions.
 */
export const createSuperHubVersionSlice: StateCreator<
  SuperHubStore & SuperHubVersionSlice,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  SuperHubVersionSlice
> = (set, get) => ({
  versions: {
    ...initialState,

    createVersion: async newVersion => {
      set(
        state => {
          state.versions.data?.data.push({
            base: newVersion.base,
            name: newVersion.name,
            release_stage: newVersion.release_stage || 'release',
            state: newVersion.state || 'current',
            updated_at: new Date().toISOString(),
            uri: `/versions/${newVersion.name}/PENDING`,
            visibility: newVersion.visibility || 'hidden',
          });
          state.versions.data.total += 1;
        },
        false,
        actionLog('versions.createVersion', newVersion),
      );

      const request = fetcher<ReadVersionType>(`${get().apiBaseUrlWithoutVersion}/versions`, {
        method: 'POST',
        body: JSON.stringify(newVersion),
      });

      await mutate<ReadVersionCollectionType>(
        get().versions.swrKey,
        request.then(({ data }) =>
          produce(get().versions.data, draft => {
            draft.data[draft.data.length - 1] = { ...data };
          }),
        ),
        { revalidate: false },
      );

      return request;
    },

    deleteVersion: async name => {
      set(
        state => {
          const indexToRemove = state.versions.data.data.findIndex(v => v.name === name);
          if (indexToRemove > -1) {
            state.versions.data.data.splice(indexToRemove, 1);
            state.versions.data.total -= 1;
          }
        },
        false,
        actionLog('versions.deleteVersion', name),
      );

      const request = fetcher(`${get().apiBaseUrlWithoutVersion}/versions/${name}`, {
        method: 'DELETE',
      });

      await mutate<ReadVersionCollectionType>(
        get().versions.swrKey,
        request.then(() => get().versions.data),
        { revalidate: false },
      );
    },

    initialize: ({ data, error, isLoading, swrKey }) => {
      const nextData = {
        data: data || get().versions.data,
        error,
        isLoading,
      };

      // Only continue with a state update if there are changed values. This
      // quiets down the redux devtools action logs to only contain actions
      // that contain differences.
      const hasChanges = Object.entries(nextData).some(([key, value]) => {
        return JSON.stringify(value) !== JSON.stringify(get().versions[key]);
      });
      if (!hasChanges) return;

      set(
        state => {
          const writableSwrKey = swrKey as WritableDeep<typeof swrKey>;
          const writableError = error as WritableDeep<typeof error>;
          state.versions = {
            ...state.versions,
            ...nextData,
            error: writableError,
            swrKey: writableSwrKey ?? null,
          };

          // When running on the server, we must avoid marking this store as
          // "ready" to ensure it continues receiving updates until it gets
          // initialized on the client's first render.
          state.versions.isReady = isClient;
        },
        false,
        actionLog('versions.initialize', { data, error, isLoading, swrKey }),
      );
    },

    renameVersion: async (name, newName) => {
      set(
        state => {
          const target = state.versions.data.data.find(v => v.name === name);
          if (target) {
            target.name = newName;
            target.uri = target.uri.replace(name, newName);
          }
        },
        false,
        actionLog('versions.renameVersion', { oldName: name, newName }),
      );

      const request = fetcher<ReadVersionType>(`${get().apiBaseUrlWithoutVersion}/versions/${name}`, {
        method: 'PATCH',
        body: JSON.stringify({ name: newName }),
      });

      await mutate<ReadVersionCollectionType>(
        get().versions.swrKey,
        request.then(({ data }) =>
          produce(get().versions.data, draft => {
            const target = draft.data.find(v => v.name === newName);
            if (target) {
              target.name = data.name;
              target.uri = data.uri;
            }
          }),
        ),
        { revalidate: false },
      );
      return request;
    },

    revalidate: () => {
      return mutate<ReadVersionCollectionType>(get().versions.swrKey);
    },

    updateVersion: async (name, updatedVersion) => {
      set(
        state => {
          const targetIndex = state.versions.data.data.findIndex(v => v.name === name);
          if (targetIndex > -1) {
            state.versions.data.data[targetIndex] = {
              ...state.versions.data.data[targetIndex],
              ...updatedVersion,
              name,
            };
          }
        },
        false,
        actionLog('versions.updateVersion', { name, updatedVersion }),
      );

      const request = fetcher<ReadVersionType>(`${get().apiBaseUrlWithoutVersion}/versions/${name}`, {
        method: 'PATCH',
        body: JSON.stringify({
          ...updatedVersion,
          name,
        }),
      });

      await mutate<ReadVersionCollectionType>(
        get().versions.swrKey,
        request.then(({ data }) =>
          produce(get().versions.data, draft => {
            const targetIndex = draft.data.findIndex(v => v.name === name);
            if (targetIndex > -1) {
              draft.data[targetIndex] = data;
            }
          }),
        ),
        { revalidate: false },
      );
      return request;
    },
  },
});

export * from './Initialize';
